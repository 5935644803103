<template>
  <div class="market-place-rewards">
    coming soon
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'MarketPlaceRewards',
  data() {
    return {
      readingBusyName: 'FOLLOWINGLISTREADING',

      init: false,
      followingSerials: [],

      showAddFriendModal: false,
    };
  },
  components: {
  },
  computed: {

  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.market-place-rewards {
  padding: 0 .5rem;
}
</style>
  